<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      users: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchUsers: null,
      SearchMode: false,
      user_info: {},
      data: {},
      new_pass: "",
    };
  },
  methods: {
    editUser() {
      delete this.user_info.created;
      delete this.user_info.updated;
      console.log(this.user_info);
      this.http
        .put("marketing-team-members/", this.user_info.id, this.user_info)
        .then((res) => {
          if (res.status) {
            this.popup.alert({
              title: "",
              msg: "messages.success",
            });
            this.get(this.page);
            document.querySelector("#editModal .close-modal-btn").click()

          }
        });
    },
    editPass() {
      console.log(this.user_info.id);
      this.http
        .post("marketing-team-members/update-password", {
          id: this.user_info.id,
          password: this.new_pass,
        }, null, true)
        .then((res) => {
          if (res.status) {

            document.querySelector(".btn-close-editpass")?.click();

            this.popup.alert({
              title: "messages.success",
              msg: "messages.password_reset",
            });

          }

        });
    },
    addMarketingTeamMember() {
      this.data.company_id =
        this.$store?.state?.authfack?.selected_company_id;
      console.log({ data: this.data });
      this.http.post("marketing-team-members", this.data, null, true).then((res) => {
        if (res.status) {
          this.popup.alert({ title: '', msg: 'popups.success' })
          this.data = {};
          this.get(this.page);
          document.querySelector("#addModal .close-modal-btn")?.click()

        }
      });
    },
    search() {
      this.SearchMode = true;
      console.log(this.searchUsers);
      this.tot_pages = 0;
      this.http
        .post("marketing-team-members/search", {
          limit: this.limit,
          page: this.page,
          search: this.searchUsers,
          col: "name",
        }, null, true)
        .then((res) => {
          this.users = res.data;
        });
    },
    cancelSearchMode() {
      this.searchUsers = "";
      this.SearchMode = false;
      this.get(this.page);
    },
    deleteUser(user) {
      var data = {
        title: "popups.deleteuser",
        msg: "popups.deletemsg",
        buttons: [
          {
            text: "Delete",
            closer: true,
            color: "danger",
          },
          {
            text: "Cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("marketing-team-members", user.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },
    get(page) {
      console.log(this.userstable);
      this.http
        .post("marketing-team-members/pagination", {
          limit: this.limit,
          page: page,
        }, null, true)
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.users = res.data;
        });
    },
    setUserInfo(user) {

      this.user_info = JSON.parse(JSON.stringify(user));
      this.user_info.birthdate = this.user_info.birthdate.split('T')[0]

    },
  },
  created() {
    this.get(this.page);
  },
};
</script>

<template>
  <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
    :title="$t('menu.menuitems.marketing.subItems.marketing_team_management')" />
  <div class="row">
    <div class="col-6">
      <div class="search-box chat-search-box" style="width: 250px">
        <div class="position-relative">
          <input @keyup="search()" type="text" class="form-control mb-2 fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :placeholder="$t('users.searchplaceholder')" v-model="searchUsers"
            style="background-color: #2a3042 !important" />
          <i class="bx bx-search-alt search-icon text-light"></i>
          <button @click="cancelSearchMode()" v-if="SearchMode" class="btn-close text-light"
            style="position: absolute; top: 12px; left: 250px"></button>
        </div>
      </div>
    </div>
    <div class="col-6" style="display: flex; justify-content: flex-end">
      <button type="button" class="btn btn-light float-end mb-4" data-bs-toggle="modal" data-bs-target="#addModal">
        <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
      </button>
    </div>
  </div>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
    <thead>
      <tr class="text-light text-center" style="background-color: #2a3042 !important">
        <th scope="col">{{ $t("users.userstable.id") }}</th>
        <th scope="col">
          {{ $t("marketing.team_members.table.name") }}
        </th>
        <th scope="col">
          {{ $t("marketing.team_members.table.tel") }}
        </th>
        <th scope="col">
          {{ $t("marketing.team_members.table.email") }}
        </th>
        <th scope="col">
          {{ $t("marketing.team_members.table.rate") }}
        </th>
        <th scope="col">
          {{ $t("marketing.team_members.table.birthdate") }}
        </th>
        <th scope="col">{{ $t("users.userstable.created") }}</th>
        <th scope="col">{{ $t("users.userstable.updated") }}</th>
        <th scope="col">{{ $t("users.userstable.operations") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="text-center" v-for="(user, index) in users" :key="user">
        <td>{{ index + 1 }}</td>
        <td>{{ user?.name }}</td>
        <td>{{ user?.tel }}</td>
        <td>{{ user?.email }}</td>
        <td>
          {{ user?.rate ? user?.rate : "--" }}
        </td>
        <td v-if="user?.birthdate">
          {{ user?.birthdate?.split("T")[0] }}
        </td>
        <td v-if="user?.created">{{ user?.created?.split("T")[0] }}</td>
        <td v-if="user?.updated">{{ user?.updated?.split("T")[0] }}</td>
        <td class="d-flex justify-content-end">
          <button type="button" class="btn btn-primary me-2 btn-sm" data-bs-target="#editModal" data-bs-toggle="modal"
            @click="setUserInfo(user)">
            {{ $t("popups.edit") }}
          </button>
          <button type="button" class="btn btn-primary me-2 btn-sm" data-bs-target="#editMemberPassModal"
            data-bs-toggle="modal" @click="setUserInfo(user)">
            {{ $t("popups.resetpass") }}
          </button>
          <a class="btn btn-danger btn-sm me-2" :class="{}" href="javascript:void(0)" role="button"
            @click="deleteUser(user)">{{ $t("popups.delete") }}</a>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- end  table -->
  <!--Start Add Modal-->
  <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addMarketingTeamMember()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.add") }}
            </h5>

          </div>

          <div class="modal-body">
            <label for="name">{{ $t("popups.name") }}</label>
            <input v-model="data.name" id="name" required class="form-control mb-2" type="text"
              :placeholder="$t('popups.name')" />

            <label for="email">{{ $t("popups.email") }}</label>
            <input v-model="data.email" id="email" required class="form-control mb-2" type="email"
              :placeholder="$t('popups.email')" />
            <label for="email">{{ $t("popups.password") }}</label>
            <input v-model="data.pass" id="password" required class="form-control mb-2" type="password"
              :placeholder="$t('popups.password')" />

            <label for="tel">{{ $t("popups.tel") }}</label>
            <input v-model="data.tel" id="tel" required class="form-control mb-2" type="tel"
              :placeholder="$t('popups.tel')" />

            <label for="birthdate">{{ $t("popups.birthdate") }}</label>
            <input v-model="data.birthdate" id="birthdate" required class="form-control mb-2" type="date"
              :placeholder="$t('popups.birthdate')" />
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.add") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn close-modal-btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Modal-->

  <!-- Edit Modal -->
  <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="editUser()">
          <div class="modal-header" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.edit") }}
            </h5>

          </div>

          <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
            <label for="name">{{ $t("popups.name") }}</label>
            <input v-model="user_info.name" id="name" required class="form-control mb-2" type="text"
              :placeholder="$t('popups.name')" />

            <label for="email">{{ $t("popups.email") }}</label>
            <input v-model="user_info.email" id="email" required class="form-control mb-2" type="email"
              :placeholder="$t('popups.email')" />

            <label for="tel">{{ $t("popups.tel") }}</label>
            <input v-model="user_info.tel" id="tel" required class="form-control mb-2" type="tel"
              :placeholder="$t('popups.tel')" />

            <label for="birthdate">{{ $t("popups.birthdate") }}</label>
            <input v-model="user_info.birthdate" id="birthdate" required class="form-control mb-2" type="date"
              :placeholder="$t('popups.birthdate')" />
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.edit") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn close-modal-btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--end Edit Modal-->
  <!--   Apps  Pagination     -->

  <!--Edit Pass Modsl-->
  <div class="modal fade" id="editMemberPassModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="editPass()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.resetpass") }}
            </h5>

          </div>

          <div class="modal-body">
            <label for="pass" class="form-label">{{
              $t("popups.resetpass")
            }}</label>
            <input v-model="new_pass" required type="password" class="form-control" id="pass"
              :placeholder="$t('popups.resetpass')" />
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.edit") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--Edit pass Modal-->
  <ul v-if="tot_pages >= 20" class="pagination pagination-rounded justify-content-center mb-2">
    <li class="page-item" :class="{ disabled: page == 1 }">
      <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>
    <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
      <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
        p
      }}</a>
    </li>

    <li class="page-item" :class="{ disabled: page == total_pages }">
      <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
  </ul>
  <!--end-->
</template>
